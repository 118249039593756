<template>
  <div class="page-content">
    <div class="card row">
      <!-- <div class="card-body row col-lg-12">
        <div class="col-9 col-sm-9">
          <form class="search-form" @submit.prevent="doSearch()">
            <div class="input-group border rounded-sm">
              <div class="input-group-prepend">
                <div class="input-group-text border-0 rounded-sm">
                  <FeatherIcon type="search" class="icon-md cursor-pointer" />
                </div>
              </div>
              <input v-model="search_keyword" type="text" class="form-control  border-0 rounded-sm" id="searchForm" placeholder="Search here...">
            </div>
          </form>
        </div>
        <div class="col-3 col-sm-3">
          <button class="btn btn-primary" @click="showModalAdd">Add New Product</button>
        </div>
      </div> -->
      <div class="card-body row col-lg-12">
        <div class="row col-lg-12">
          <div class="col-9 col-sm-9">
            <form class="search-form" @submit.prevent="doSearch()">
              <div class="input-group border rounded-sm">
                <div class="input-group-prepend">
                  <div class="input-group-text border-0 rounded-sm">
                    <FeatherIcon type="search" class="icon-md cursor-pointer" />
                  </div>
                </div>
                <input v-model="search_keyword" type="text" class="form-control  border-0 rounded-sm" id="searchForm" placeholder="Search here...">
              </div>
            </form>
          </div>
          <div class="col-3 col-sm-3">
            <button class="btn btn-primary" @click="showModalAdd">Add New Product</button>
          </div>
        </div>
        <div class="table-responsive mt-2">
          <table class="table table-hover">
            <thead>
              <tr>
                <th width="15%">Thumbnail</th>
                <th>Name</th>
                <th>Description</th>
                <th>Price</th>
                <th width="5%">#</th>
              </tr>
            </thead>
            <tbody v-loading="loading.table">
              <tr v-for="(item, i) in product_list" :key="i">
                <td>
                  <el-image
                    style="width: 120px; height: 120px"
                    :src="item.thumbnail"
                    :fit="fit"></el-image>
                </td>
                <td>{{ item.name }}</td>
                <td>
                  <!-- <el-popover v-if="item.description.length > 50"
                    placement="right"
                    width="300"
                    trigger="hover">
                    <h6 class="mb-2">Description</h6>
                    <p class="mb-2">
                      {{ item.description}}
                    </p>
                    <span slot="reference" style="padding: 6px">
                      {{ truncate(item.description, { length: 50 }) }}
                    </span>
                  </el-popover>
                  <span v-else> -->
                  {{ truncate(item.description, { length: 50 }) }}
                  <!-- </span> -->
                </td>
                <td>Rp.{{ item.price.toLocaleString() }}</td>
                <td>
                  <button @click="showModalEdit(item)" class="btn btn-outline-primary btn-sm mr-2">
                    <font-awesome icon="edit"/>
                  </button>
                  <button @click="confirmDeleteProduct(item)" class="btn btn-outline-danger btn-sm mr-2">
                    <font-awesome icon="trash"/>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <el-empty :image="emptyImage" v-if="total_row == 0" description="No data"></el-empty>
        </div>
        <b-pagination v-if="total_row > 10" v-model="currentPage" :total-rows="total_row" :per-page="10"/>
      </div>
      <!-- <div class="col-md-12 mb-3 row">
        <div class="col-lg-3 mb-2" v-for="(item, i) in product_list" :key="i">
          <div class="card">
            <div class="thumbnail-product" :style="'background-image: url('+ item.thumbnail +')'"></div>
            <div class="card-body" style="min-height: 50px; overflow-y: auto">
              <span>{{ item.name }}</span>
              <br/><b>Rp. {{ item.price.toLocaleString() }}</b>
              <p>{{ item.description }}</p>
            </div>
            <div class="card-footer d-flex flex-row">
              <button class="btn btn-outline-primary btn-sm mr-2">
                <font-awesome icon="edit"/>
              </button>
              <button @click="confirmDeleteProduct" class="btn btn-outline-danger btn-sm mr-2">
                <font-awesome icon="trash"/>
              </button>
            </div>
          </div>
        </div>
        <el-empty :image="emptyImage" v-if="total_row == 0" description="No data"></el-empty>
      </div> -->
      <b-modal :no-close-on-esc="!canCloseModalAdd" :no-close-on-backdrop="!canCloseModalAdd" v-model="show_modal_add" title="Create New Product" hide-footer>
        <b-form ref="formAddQr" @submit.prevent="submitProduct" @reset="closeModalAdd">
          <b-form-group
            id="product-add-name"
            label="Name"
            label-for="product-add-name">
            <b-form-input
              id="product-add-name"
              v-model="v$.form.name.$model"
              type="text"
              placeholder="Enter product name"
              required />
            <b-form-invalid-feedback :state="!v$.form.name.$invalid" v-if="v$.form.name.$errors[0]">
              {{ $t('validation.' + v$.form.name.$errors[0].$validator) }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            id="product-add-description"
            label="Description"
            label-for="product-add-description">
            <b-form-textarea
              id="product-add-description"
              v-model="v$.form.description.$model"
              rows="9"
              placeholder="Enter product description"
              required />
            <b-form-invalid-feedback :state="!v$.form.description.$invalid" v-if="v$.form.description.$errors[0]">
              {{ $t('validation.' + v$.form.description.$errors[0].$validator) }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            id="product-add-price"
            label="Price"
            label-for="product-add-price">
            <b-input-group prepend="Rp">
              <b-form-input
                id="product-add-price"
                v-model="v$.form.price.$model"
                type="text"
                v-money="currency_mask"
                placeholder="Enter product price"
                required />
            </b-input-group>
            <b-form-invalid-feedback :state="!v$.form.price.$invalid" v-if="v$.form.price.$errors[0]">
              {{ $t('validation.' + v$.form.price.$errors[0].$validator) }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            id="product-add-file"
            label="Attachment"
            label-for="product-add-file">
            <b-form-file
              ref="addProductFile"
              v-model="form.file"
              accept="image/*"
              placeholder="Choose a file or drop it here."
              drop-placeholder="Drop file here."
            ></b-form-file>
            <el-progress v-if="loading.upload_file" :percentage="upload_progress" :status="upload_status"></el-progress>
          </b-form-group>
          <div class="d-flex flex-row-reverse">
            <!-- <b-button type="submit" variant="primary" class="ml-2">{{ $t('general.submit') }}</b-button> -->
            <el-button size="small" @click="submitProduct" :loading="loading.add_product" class="btn btn-sm ml-2 btn-primary">{{ $t('general.submit') }}</el-button>
            <el-button size="small" @click="show_modal_add = false" :disabled="!canCloseModalAdd" type="secondary">{{ $t('general.cancel') }}</el-button>
          </div>
        </b-form>
      </b-modal>
      <b-modal :no-close-on-esc="!canCloseModalEdit" :no-close-on-backdrop="!canCloseModalEdit" v-model="show_modal_edit" title="Update Product" hide-footer>
        <b-form ref="formAddQr" @submit.prevent="submitEditProduct" @reset="closeModalAdd">
          <b-form-group
            id="product-add-name"
            label="Name"
            label-for="product-add-name">
            <b-form-input
              id="product-add-name"
              v-model="v$.form.name.$model"
              type="text"
              placeholder="Enter product name"
              required />
            <b-form-invalid-feedback :state="!v$.form.name.$invalid" v-if="v$.form.name.$errors[0]">
              {{ $t('validation.' + v$.form.name.$errors[0].$validator) }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            id="product-add-description"
            label="Description"
            label-for="product-add-description">
            <b-form-textarea
              id="product-add-description"
              v-model="v$.form.description.$model"
              rows="9"
              placeholder="Enter product description"
              required />
            <b-form-invalid-feedback :state="!v$.form.description.$invalid" v-if="v$.form.description.$errors[0]">
              {{ $t('validation.' + v$.form.description.$errors[0].$validator) }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            id="product-add-price"
            label="Price"
            label-for="product-add-price">
            <b-input-group prepend="Rp">
              <b-form-input
                id="product-add-price"
                v-model="v$.form.price.$model"
                type="text"
                v-money="currency_mask"
                placeholder="Enter product price"
                required />
            </b-input-group>
            <b-form-invalid-feedback :state="!v$.form.price.$invalid" v-if="v$.form.price.$errors[0]">
              {{ $t('validation.' + v$.form.price.$errors[0].$validator) }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            id="product-add-file"
            label="Attachment"
            label-for="product-add-file">
            <b-form-file
              ref="addProductFile"
              v-model="form.file"
              accept="image/*"
              placeholder="Choose a file or drop it here."
              drop-placeholder="Drop file here."
            ></b-form-file>
            <el-progress v-if="loading.upload_file" :percentage="upload_progress" :status="upload_status"></el-progress>
          </b-form-group>
          <div class="d-flex flex-row-reverse">
            <!-- <b-button type="submit" variant="primary" class="ml-2">{{ $t('general.submit') }}</b-button> -->
            <el-button size="small" @click="submitEditProduct" :loading="loading.edit_product" class="btn btn-sm ml-2 btn-primary">{{ $t('general.submit') }}</el-button>
            <el-button size="small" @click="show_modal_add = false" :disabled="!canCloseModalEdit" type="secondary">{{ $t('general.cancel') }}</el-button>
          </div>
        </b-form>
      </b-modal>
    </div>
  </div>
</template>
<script>
import { VMoney } from 'v-money';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { clone, truncate } from 'lodash';

import emptyImage from '@/assets/images/undraw/empty.svg';
import placeholderImage from '@/assets/images/placeholder.jpg';
import productApi from '../../../api/commerceProducts';
import popupErrorMessages from '../../../library/popup-error-messages';

export default {
  name: 'ProductsManagement',
  metaInfo: {
    title: 'Products Management',
  },
  directives: {
    money: VMoney,
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      emptyImage,
      truncate,
      placeholderImage,
      loading: {
        upload_file: false,
        add_product: false,
        edit_product: false,
        table: false,
      },
      upload_progress: 0,
      upload_status: null,
      total_row: 0,
      page: 1,
      search_keyword: '',
      loader: null,
      loader_stack: 0,
      product_list: [],
      show_modal_add: false,
      show_modal_edit: false,
      form: {
        name: '',
        description: '',
        file: null,
        price: '0',
        attachment_ids: [],
      },
      currency_mask: {
        decimal: ',',
        thousands: '.',
        precision: 0,
      },
    };
  },
  validations() {
    return {
      form: {
        name: { required },
        description: { required },
        price: { required },
      },
    };
  },
  computed: {
    currentPage: {
      get() {
        return this.page;
      },
      set(val) {
        this.page = val;
        this.getList();
      },
    },
    workspaces() {
      return this.$store.state.workspace.workspaces.map((workspace) => ({
        text: workspace.name,
        value: workspace._id,
      }));
    },
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
    canCloseModalAdd() {
      return !this.loading.upload_file && !this.loading.add_product;
    },
    canCloseModalEdit() {
      return !this.loading.upload_file && !this.loading.edit_product;
    },
  },
  async mounted() {
    await this.getList();
  },
  methods: {
    showLoader() {
      if (this.loader_stack === 0) {
        this.loader = this.$loading.show();
      }
      this.loader_stack += 1;
    },
    hideLoader() {
      this.loader_stack -= 1;
      if (this.loader_stack === 0) {
        this.loader.hide();
      }
    },
    async getList() {
      this.showLoader();
      this.loading.table = true;
      const response = await productApi.getList({
        workspace_id: this.activeWorkspace._id,
        page: this.page,
        search_keyword: this.search_keyword,
      });
      this.loading.table = false;
      this.hideLoader();
      await popupErrorMessages(response);
      // console.log(response.data);
      this.total_row = response.data.count;
      this.product_list = response.data.rows.map((v) => {
        v.thumbnail = this.placeholderImage;
        v.thumbnail_type = 'image';
        if (v.product_attachments[0]) {
          const ath = v.product_attachments[0];
          v.thumbnail = ath.full_url;
          v.thumbnail_type = ath.mime_type.split('/')[0];
        }
        return v;
      });
    },
    showModalAdd() {
      this.form = {
        name: '',
        description: '',
        file: null,
        price: '0',
        attachment_ids: [],
      };
      this.v$.form.$touch();
      this.show_modal_add = true;
    },
    closeModalAdd() {
      this.form = {
        name: '',
        description: '',
        file: null,
        price: '0',
        attachment_ids: [],
      };
      this.show_modal_add = false;
      this.show_modal_edit = false;
    },
    async uploadAttachment() {
      if (!this.form.file) {
        return;
      }
      this.loading.upload_file = true;
      const formData = new FormData();
      formData.append('file', this.form.file);
      const response = await productApi.uploadAttachment({
        workspace_id: this.activeWorkspace._id,
        data: formData,
      }, (progressEvent) => {
        const p = parseInt((progressEvent.loaded / progressEvent.total) * 100, 0);
        this.upload_progress = p;
        if (p === 100) {
          this.upload_status = 'success';
        }
      });
      popupErrorMessages(response);
      if (this.form.attachment_ids) this.form.attachment_ids.push(response.data.attachment.id);
      else this.form.attachment_ids = [response.data.attachment.id];
      this.loading.upload_file = false;
    },
    async submitProduct() {
      this.loading.add_product = true;
      await this.uploadAttachment();
      const form = clone(this.form);
      delete form.file;
      form.price = parseInt(form.price.replace(/\D/g, ''), 0);
      const response = await productApi.create({
        workspace_id: this.activeWorkspace._id,
        data: form,
      });
      await popupErrorMessages(response);
      this.$message({
        type: this.$t('success'),
        message: this.$t('products.success.add'),
      });
      this.getList();
      this.closeModalAdd();
      this.loading.add_product = false;
    },
    confirmDeleteProduct(item) {
      this.$confirm(this.$t('products.confirm.delete'), this.$t('general.confirmation'), {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;

            const response = await productApi.delete({
              id: item.id,
              workspace_id: this.activeWorkspace._id,
            }).catch(() => {});
            await popupErrorMessages(response);

            this.$message({
              message: this.$t('products.success.delete'),
              type: 'success',
            });

            this.search = '';
            this.getList();
            instance.confirmButtonLoading = false;
            cb();
            return;
          }
          instance.confirmButtonLoading = false;
          cb();
        },
      }).catch(() => {});
    },
    showModalEdit(item) {
      const obj = clone(item);
      this.form = {
        id: obj.id,
        name: obj.name,
        description: obj.description,
        price: obj.price.toString(),
      };
      this.show_modal_edit = true;
      this.v$.form.$touch();
    },
    async submitEditProduct() {
      this.loading.edit_product = true;
      await this.uploadAttachment();
      const form = clone(this.form);
      delete form.file;
      delete form.id;
      form.price = parseInt(form.price.replace(/\D/g, ''), 0);
      const response = await productApi.update({
        id: this.form.id,
        workspace_id: this.activeWorkspace._id,
        data: form,
      });
      await popupErrorMessages(response);
      this.$message({
        type: this.$t('success'),
        message: this.$t('products.success.edit'),
      });
      this.getList();
      this.closeModalAdd();
      this.loading.edit_product = false;
    },
  },
};
</script>
<style>
.thumbnail-product {
  background-color: #ccd0d5;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  border-radius: 4px;
  box-sizing: border-box;
  /* width: 240px; */
}

.thumbnail-product::after {
  content: '';
  display: block;
  padding-top: calc(100% * (1 / 1.91));
}
</style>
